//webp に対応していないブラウザか判定
const isUnsupportedWebP = () => {
  //iOS のバージョン判定
  //https://eclair.blog/check-ios-version/
  //を改造して使用
  const osVersion = parseFloat(
    ('' + (/CPU.*OS ([0-9_]{1,5})|(CPU like).*AppleWebKit.*Mobile/i.exec(navigator.userAgent) || [0,''])[1])
      .replace('undefined', '3_2').replace('_', '.').replace('_', '')
  ) || 0;

  //iOS ではない
  if (osVersion === 0) {
    return false;
  }

  //「.」が含まれている場合は、それ以降を除去した数値が 14 以上か判定
  if (osVersion >= 14) {
    return false;
  }

  //未対応ブラウザ判定
  return true;
};

//webp 画像が srcset に含まれる img タグを picture, source, img タグのセットに変更する
const convertImg2PictureWithWebP = () => {
  //前 img タグを収集
  const images = document.querySelectorAll('img');

  //逐次実行・判定
  for (let i = 0, max = images.length; i < max; i = (i + 1) | 0) {
    //srcset 属性が空なら何もしない
    if (images.item(i).srcset === '') {
      continue;
    }

    //srcset 内に webp が存在しなければ何もしない
    if (images.item(i).srcset.indexOf('.webp') === -1) {
      continue;
    }

    //src, srcset, alt, class を保持
    const srcset = images.item(i).srcset;
    const className = images.item(i).className;

    //参照している画像の隣に picture タグを作成
    const picture = document.createElement('picture');
    picture.className = className;
    images.item(i).insertAdjacentElement('beforebegin', picture);

    //picture の中に source を作成して挿入
    const source = document.createElement('source');
    source.srcset = srcset;
    source.type = 'image/webp';
    picture.appendChild(source);

    //元の img タグから srcset, class を削除
    images.item(i).className = '';
    images.item(i).srcset = '';

    //source タグの後に img を移動する
    picture.insertBefore(images.item(i), source.nextSibling);
  }
};

document.addEventListener('DOMContentLoaded', () => {
  //webp 対応ブラウザなら何もしない
  if ( ! isUnsupportedWebP()) {
    return;
  }

  //ループさせる
  const loop = () => {
    console.log(new Date().getTime());
    //画像書き換え
    convertImg2PictureWithWebP();

    //1.5 秒後に再起動
    setTimeout(loop, 1500);
  };

  //ループ開始
  loop();
});
